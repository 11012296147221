<template>
<div>
  <v-card flat>
    <v-card-title>Events
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" dark class="mb-2" style="text-transform:none" @click="newEvent">
        <v-icon left>mdi-plus</v-icon>
        Add new
      </v-btn>
    </v-card-title>
    
    <v-card-subtitle>Create events within your community. Your residents will be able to answer if they'll be attending or not.</v-card-subtitle>
    <v-card-text>
      <v-row class="mb-2">
        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
          <v-text-field v-model="filterEvents" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-skeleton-loader type="list-item-two-line" :loading="!eventsLoaded" transition="fade-transition">
        <v-data-table :headers="headers" :items="events" sort-by="date.date" :search="filterEvents" :items-per-page="25">
          <template v-slot:item.attendance="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip class="ma-1" label color="success" x-small v-on="on">{{ item.attendance['going'].length }}</v-chip>
              </template>
              <span>Confirmed</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip class="ma-1" label color="warning" x-small v-on="on">{{ item.attendance['maybe'].length }}</v-chip>
              </template>
              <span>Maybe</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip class="ma-1" label color="error" x-small v-on="on">{{ item.attendance['not going'].length }}</v-chip>
              </template>
              <span>Not Going</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip class="ma-1" label x-small v-on="on">{{ item.attendance['not answered'] }}</v-chip>
              </template>
              <span>Not Answered</span>
            </v-tooltip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                  <v-list-item @click="editEvent(item)" dense>
                    <v-list-item-content>
                      Edit
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="viewAttendance(item)" dense>
                    <v-list-item-content>
                      View Attendance
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="eventToDelete(item)" dense>
                    <v-list-item-content>
                      Delete
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
              </v-list>
            </v-menu>
          </template>  
        </v-data-table>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>

  <v-dialog v-model="deleteDialog" persistent max-width="400">
    <v-card>
      <v-card-title>Delete Event</v-card-title>
      <v-card-text>Are you sure you want to delete this event?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn text @click="postDeleteEvent" color="error" :loading="saving">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="attendanceDialog" max-width="800">
    <v-card class="mt-0 pt-0">
      <v-card-title>{{ selectedEvent.name }} attendance</v-card-title>
      <v-card-subtitle class="pl-6">View the residents response to the event</v-card-subtitle>
      <v-card-text class="mt-0 pt-0">
        <!-- <v-row class="mt-0 pt-0">
          <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6" class="mt-0 pt-0">
            <v-text-field v-model="filterResidents" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details class="mt-0 pt-0"></v-text-field>
          </v-col>
        </v-row> -->
        <v-tabs v-model="tab" @change="getResidentList">
          <v-tab>Confirmed</v-tab>
          <v-tab>Maybe</v-tab>
          <v-tab>Not going</v-tab>
          <v-tab>Not answered</v-tab>

          <v-tab-item>
            <v-card flat>
              <v-skeleton-loader type="list-item-two-line" :loading="loadingList" transition="fade-transition">
                <v-data-table :headers="attendanceHeaders" :items="attendanceItems" :search="filterResidents" :items-per-page="25">

                </v-data-table>
              </v-skeleton-loader>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-skeleton-loader type="list-item-two-line" :loading="loadingList" transition="fade-transition">
                <v-data-table :headers="attendanceHeaders" :items="attendanceItems" :search="filterResidents" :items-per-page="25">

                </v-data-table>
              </v-skeleton-loader>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-skeleton-loader type="list-item-two-line" :loading="loadingList" transition="fade-transition">
                <v-data-table :headers="attendanceHeaders" :items="attendanceItems" :search="filterResidents" :items-per-page="25">

                </v-data-table>
              </v-skeleton-loader>
            </v-card>
          </v-tab-item>
          <v-tab-item >
            <v-card flat>
              <v-skeleton-loader type="list-item-two-line" :loading="loadingList" transition="fade-transition">
                <v-data-table :headers="attendanceHeaders" :items="attendanceItems" :search="filterResidents" :items-per-page="25">

                </v-data-table>
              </v-skeleton-loader>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>

  <event ref="event" :dialog="true" :dialogTitle="eventDialogTitle" :dialogSubtitle="eventDialogSubtitle" :dialogOpen="eventDialog" 
                    @cancel="eventDialog = false" @saved="addEvent2Table" @update="updateEvent"></event>
</div>
</template>

<script>
import { API } from '@/inc/api';
import Event from '@/components/Event.vue';

export default {
  data: () => ({
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name',
      },
      { text: 'Description', value: 'description', sortable: true },
      { text: 'Location', value: 'location', sortable: true },
      { text: 'Date', value: 'date.date', sortable: true, width: '20%' },
      { text: 'Starting Time', value: 'date.fromTime', sortable: true },
      { text: 'Ending Time', value: 'date.toTime', sortable: true },
      { text: 'Attendance', value: 'attendance', align: 'center', sortable: false },
      { text: 'Actions', value: 'action', align: 'right', sortable: false },
    ],
    eventsLoaded: false,
    deleteDialog: false,
    itemToDelete: 0,
    events: [],
    saving: false,

    filterEvents: '',
    filterResidents: '',

    tab: 0,
    attendanceDialog: false,
    selectedEvent: {},
    attendanceHeaders: [
      { text: 'Resident', value: 'fullName', sortable: true },
      { text: 'Unit Type', value: 'buildingType', sortable: true },
      { text: 'Unit', value: 'unitNumber', sortable: true },
    ],
    attendanceItems: [],
    loadingList: false,

    eventDialog: false,
    eventDialogTitle: 'New Event',
    eventDialogSubtitle: 'Configure your event',
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  mounted () {
    this.init();
  },

  methods: {
    async init() {
      let api = new API();

      try {
        this.eventsLoaded = false;
        let res = await api.get('/admin/events');
        this.eventsLoaded = true;

        if( !res.data.status && res.data.error ) {
          console.log('ERROR: ' + res.data.message );
          return;
        }
        
        // alert(JSON.stringify(response.data.data));
        this.events = res.data.data;
      }
      catch(error) {
        this.eventsLoaded = true;
        console.log(error);
      }
    },
    editEvent(item) {
      this.$refs.event.load( item );
      this.eventDialog = true;
    },
    eventToDelete (item) {
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
    async postDeleteEvent() {
      let api = new API();

      try {
        this.saving = true;
        let res = await api.delete(`/admin/event/${this.itemToDelete.id}`);
        this.saving = false;

        // If everything is ok, go to thankyou page
        if( !res.data.status && res.data.error ) {
          console.log(res.data.message);
          return;
        }
        else if( !res.data.status ) {
          console.log(res.data.message);
          return;
        }

        const index = this.events.indexOf(this.itemToDelete);
        this.events.splice(index, 1);

        this.deleteDialog = false;
        this.mxShowMessage( 'Event deleted', 'success' );
      }
      catch(error) {
        this.mxShowMessage( error, 'error' );
        this.saving = false;
      }
    },
    viewAttendance(item) {
      this.selectedEvent = item;
      this.tab = 0;
      this.getResidentList();
      this.attendanceDialog = true;
    },
    async getResidentList() {
      let endpoint = '';
      switch(this.tab) {
        case 0: endpoint = 'going'; break;
        case 1: endpoint = 'maybe'; break;
        case 2: endpoint = 'not going'; break;
        case 3: endpoint = 'notanswered'; break;
      }

      let api = new API();

      try {
        this.loadingList = true;
        let res = await api.get(`/admin/event/${this.selectedEvent.id}/${endpoint}`);
        this.loadingList = false;

        if( !res.data.status && res.data.error ) {
          console.log('ERROR: ' + res.data.message );
          return;
        }
        
        this.attendanceItems = res.data.data;
      }
      catch(error) {
        this.loadingList = false;
        console.log(error);
      }
    },
    openDialog() {
      this.nameError = this.locationError = this.descriptionError = this.fromTimeError = this.toTimeError = '';
      this.dialog = true;
    },
    newEvent() {
      this.eventDialog = true;
    },
    addEvent2Table(event) {
      let found = this.events.find(e => {
        return e.id == event.id;
      });

      if( found != undefined && found != null ) 
        return; // is an update

      this.events.push(event);
      this.mxShowMessage( 'Event created', 'success' );
    },
    updateEvent(event) {
      let found = this.events.find(e => {
        return e.id == event.id;
      });
      
      if( found == undefined || found == null ) 
        return; // is not an update


      found.name = event.name;
      found.description = event.description;
      found.where = event.where;
      found.date = event.date;
      found.goingQty = event.goingQty;
      found.maybeQty = event.maybeQty;
      found.notGoingQty = event.notGoingQty;
      found.notAnswered = event.notAnswered;

      this.mxShowMessage( 'Event updated', 'success' );
    },

  },
  components: {
    Event
  }
}
</script>

<style scoped>

</style>